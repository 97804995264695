import { API } from '@aws-amplify/api'
import { Auth } from '@aws-amplify/auth'

export function configureAmplify(
  cognitoRegion: string,
  cognitoUserPoolId: string,
  cognitoAppClientId: string
): void {
  Auth.configure({
    mandatorySignIn: true,
    region: cognitoRegion,
    userPoolId: cognitoUserPoolId,
    userPoolWebClientId: cognitoAppClientId,
  })
  API.configure({
    endpoints: [
      {
        name: 'app-unauthenticated',
        endpoint: '/api',
      },
      {
        name: 'app-authenticated',
        endpoint: '/api',
        custom_header: async () => ({
          CognitoJwtToken: (await Auth.currentSession()).getAccessToken().getJwtToken(),
        }),
      },
    ],
  })
}
