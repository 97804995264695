import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import NotFound from 'components/NotFound'
import React from 'react'

import { ApiError } from '../types'

export const useHandleError = (error: ApiError | null): JSX.Element | null => {
  if (error === null) return null
  try {
    const response = JSON.parse(error.request.response)
    const message = response.message

    if (message === 'Forbidden resource') {
      // If wrong Account type of user
      return <NotFound />
    }

    return <ErrorPage />
  } catch (parseError) {
    console.error('Error parsing API response:', parseError)
    return <ErrorPage />
  }
}
