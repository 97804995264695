export enum AllocationStatus {
  START = 'START',
  DRAFT = 'DRAFT',
  OUTSTANDING = 'OUTSTANDING',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
}

export enum AllocationAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  RELEASE = 'RELEASE',
  UNRELEASE = 'UNRELEASE',
  ACKNOWLEDGE = 'ACKNOWLEDGE',
  UNACKNOWLEDGE = 'UNACKNOWLEDGE',
}
