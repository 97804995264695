import { AccountType } from '@shared/enums'

import { API } from './aws-api'

export interface ProfileDTO {
  id: string
  email: string
  givenName: string
  familyName: string
  organizationId: string
  organizationName: string
  accountType: AccountType
  isOrderSyndicateEmailsSuppressed: boolean
  isIoiEnabled: boolean
  disclaimerAcceptedAt?: Date
}

export const getUserProfile = async (): Promise<ProfileDTO> => {
  return await API<ProfileDTO>().get({
    path: `/v1/profile`,
  })
}

export async function requestAccess(): Promise<void> {
  void (await API().post({
    path: '/v1/profile/request-access',
  }))
}

export async function updateDisclaimer(isAccepted: boolean): Promise<void> {
  void (await API().patch({
    path: '/v1/profile/disclaimer',
    init: { body: { isAccepted } },
  }))
}
