import React from 'react'
import { BundleStore } from 'src/store/bundleStore/bundleStore'
import { OrderStore } from 'src/store/orderStore/orderStore'

import { AlertStore } from './alertStore/alertStore'

export class RootStore {
  orderStore: OrderStore
  bundleStore: BundleStore
  alertStore: AlertStore

  constructor() {
    this.orderStore = new OrderStore(this)
    this.bundleStore = new BundleStore(this)
    this.alertStore = new AlertStore(this)
  }

  clear(): void {
    this.orderStore.clear()
    this.bundleStore.clear()
    this.alertStore.clear()
  }
}

export const rootStore = new RootStore()
const RootStoreContext = React.createContext(rootStore)
export const useStores = (): RootStore => React.useContext(RootStoreContext)
