import { API as AmplifyAPI } from '@aws-amplify/api'
import { APIInput } from 'src/services/const'

import { onError } from '../lib/errorLib'
import { handleLogout } from './auth'

interface TypedAPI<T> {
  get: (input: APIInput) => Promise<T>
  post: (input: APIInput) => Promise<T>
  put: (input: APIInput) => Promise<T>
  del: (input: APIInput) => Promise<T>
  patch: (input: APIInput) => Promise<T>
}

export function API<T>(): TypedAPI<T> {
  const get = async ({ apiName = 'app-authenticated', path, init = {} }: APIInput): Promise<T> => {
    return await AmplifyAPI.get(apiName, path, init).catch(async (error) => {
      if (error?.response?.data?.error === 'Access Token has been revoked') {
        await handleLogout()
      }
      onError({ error: error, httpMethod: 'GET', apiName, path, init })
    })
  }

  const post = async ({ apiName = 'app-authenticated', path, init = {} }: APIInput): Promise<T> => {
    return await AmplifyAPI.post(apiName, path, init).catch((error) =>
      onError({ error: error, httpMethod: 'POST', apiName, path, init })
    )
  }

  const put = async ({ apiName = 'app-authenticated', path, init = {} }: APIInput): Promise<T> => {
    return await AmplifyAPI.put(apiName, path, init).catch((error) =>
      onError({ error: error, httpMethod: 'PUT', apiName, path, init })
    )
  }

  const patch = async ({
    apiName = 'app-authenticated',
    path,
    init = {},
  }: APIInput): Promise<T> => {
    return await AmplifyAPI.patch(apiName, path, init).catch((error) =>
      onError({ error: error, httpMethod: 'PATCH', apiName, path, init })
    )
  }

  const del = async ({ apiName = 'app-authenticated', path, init = {} }: APIInput): Promise<T> => {
    return await AmplifyAPI.del(apiName, path, init).catch((error) =>
      onError({ error: error, httpMethod: 'DEL', apiName, path, init })
    )
  }

  return {
    get,
    post,
    put,
    del,
    patch,
  }
}
