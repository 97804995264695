import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import { LoadingScreen } from '@ppui/ui-components'
import { AlertComponent } from 'components/NewToast/Alert'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { AppRoutes } from './AppRoutes'
import { reportPageView } from './config/configureGoogleAnalytics'
import { useGetFeatureFlags } from './lib/queries/useGetFeatureFlags'

export const App = (envName: any): JSX.Element => {
  const { isLoading } = useGetFeatureFlags()
  const location = useLocation()

  useEffect(() => {
    return reportPageView(location.pathname)
  }, [location.pathname])

  if (isLoading) return <LoadingScreen />

  return (
    <>
      <AlertComponent />
      <AppRoutes />
    </>
  )
}
