import * as Sentry from '@sentry/react'

interface onErrorProps {
  error: any
  httpMethod?: string
  apiName?: string
  path?: string
  init?: { body?: unknown }
  AWSAmplifyMethod?: string
}

export function onError({
  error,
  httpMethod,
  apiName,
  path,
  init,
  AWSAmplifyMethod,
}: onErrorProps): void {
  console.warn(error, error?.response)

  if (error?.response?.status >= 500) {
    Sentry.captureMessage('API request failed with 5xx response', {
      level: Sentry.Severity.Warning,
      extra: {
        errorResponse: error?.response,
        httpMethod,
        apiName,
        path,
        init,
        AWSAmplifyMethod,
      },
    })
  }
  throw error
}
