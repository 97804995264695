import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import React, { ReactNode, useCallback, useEffect } from 'react'

interface AuthContextType {
  signOut: () => void
  hasLoaded: boolean
  isAuthenticated: boolean
  env: string
}

const AuthContext = React.createContext<AuthContextType>({
  signOut: () => {},
  hasLoaded: false,
  isAuthenticated: false,
  env: '',
})

export function AuthProvider(props?: {
  env: string
  children: ReactNode
  isMfe: boolean
}): JSX.Element {
  const env = props?.env ?? ''
  const isMfe = props?.isMfe ?? false
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false)

  const listener = useCallback(
    (data) => {
      const event = data?.payload?.event

      switch (event) {
        case 'signIn':
          setIsAuthenticated(true)
          break
        case 'signOut':
          setIsAuthenticated(false)
          break
      }
    },
    [setIsAuthenticated]
  )

  useEffect(() => {
    Hub.listen('auth', listener)

    async function updateAuthState(): Promise<void> {
      try {
        const { getSession } = isMfe
          ? await import('@pp/utils')
          : { getSession: async () => await Auth.currentSession() }
        const session = await getSession()
        setIsAuthenticated(session.isValid())
      } catch (e) {
      } finally {
        setHasLoaded(true)
      }
    }

    if (!hasLoaded) {
      void updateAuthState()
    }

    return () => Hub.remove('auth', listener)
  }, [listener, hasLoaded, setIsAuthenticated, setHasLoaded, isMfe])

  const value = {
    signOut: async () => {
      const { signOut } = isMfe
        ? await import('@pp/utils')
        : { signOut: async () => await Auth.signOut() }
      await signOut()
      window.location.replace('/')
    },
    hasLoaded,
    isAuthenticated,
    env,
  }

  return <AuthContext.Provider value={value}>{props?.children}</AuthContext.Provider>
}

export function useAuth(): AuthContextType {
  return React.useContext(AuthContext)
}

export { AuthContext }
