import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { CACHED_QUERY_PROPS } from '../../constants/queries'
import { getFeatureFlags } from '../../services/feature-flags'
import { ReactQueryKey } from '../types'

export interface FeatureFlagsDto {
  name: string
  description?: string
  enabled: boolean
}

export function useGetFeatureFlags(): UseQueryResult<FeatureFlagsDto[]> {
  return useQuery<FeatureFlagsDto[]>(ReactQueryKey.FEATURE_FLAGS, getFeatureFlags, {
    ...CACHED_QUERY_PROPS,
    retry: true,
    retryDelay: 3000,
    refetchInterval: 30 * 1000,
  })
}

export const useQueryClientFeatureFlags = (): {
  currentFeatureFlags?: FeatureFlagsDto[]
} => {
  const queryClient = useQueryClient()

  return {
    currentFeatureFlags: queryClient.getQueryData([ReactQueryKey.FEATURE_FLAGS]),
  }
}
