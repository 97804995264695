import ReactGA from 'react-ga'

let TRACK_GA = false

export const configureGoogleAnalytics = (googleAnalyticsTrackingId?: string): void => {
  if (googleAnalyticsTrackingId != null) {
    TRACK_GA = true
    ReactGA.initialize(googleAnalyticsTrackingId)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}

export const reportPageView = (page: any): void => {
  if (TRACK_GA) {
    ReactGA.pageview(page)
  }
}
