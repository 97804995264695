import { FeatureFlagsDto } from 'src/lib/queries/useGetFeatureFlags'

import { API } from './aws-api'

export async function getFeatureFlags(): Promise<FeatureFlagsDto[]> {
  return await API<FeatureFlagsDto[]>().get({
    path: '/v1/feature-flags',
    apiName: 'app-unauthenticated',
  })
}
