import { always, identity, ifElse, pathOr, pipe, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

export const toKebabCase = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // convert camel case to kebab case
    .replace(/[_\s]+/g, '-') // replace underscores and spaces with hyphens
    .toLowerCase() // convert to lowercase
}

export const isValidJSON = (errorResponse: string): boolean => {
  try {
    JSON.parse(errorResponse)
    return true
  } catch (e) {
    return false
  }
}

export const extractErrorCode = ifElse(
  pipe(pathOr('', ['request', 'response']), isValidJSON),
  pipe(pathOr('', ['request', 'response']), JSON.parse, propOr(undefined, 'statusCode')),
  always(undefined)
)

export const getComNameOrOrgName = (commonName: string | undefined, orgName: string): string =>
  ifElse(isNotNilOrEmpty, identity, always(orgName))(commonName)
