import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { getUserProfile, ProfileDTO } from '../../services/users'
import { ApiError, ReactQueryKey } from '../types'
import { getRefetchInterval, retryIfErrorIsNotConnectionOrDistributionGroup } from '../utils/core'

export function useGetUserProfile(): UseQueryResult<ProfileDTO, ApiError> {
  return useQuery<ProfileDTO, ApiError>(
    [ReactQueryKey.USER_PROFILE],
    async () => await getUserProfile(),
    {
      cacheTime: 10000,
      notifyOnChangeProps: ['data', 'error'],
      refetchIntervalInBackground: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: (_, query) => getRefetchInterval(query.state.status, 10000),
      retry: (_, error) => retryIfErrorIsNotConnectionOrDistributionGroup(error),
    }
  )
}

export const useQueryClientUserProfile = (): {
  profileDTO?: ProfileDTO
} => {
  const queryClient = useQueryClient()

  return {
    profileDTO: queryClient.getQueryData([ReactQueryKey.USER_PROFILE]),
  }
}
