import { Currency, OrderStatus } from '@shared/enums'
import { DealSetupCurrency } from 'components/Orders/Demands/types'
import { Array as A, Function as F, Option as O } from 'effect'
import { DateTime } from 'luxon'
import {
  __,
  allPass,
  always,
  anyPass,
  cond,
  converge,
  curry,
  defaultTo,
  either,
  equals,
  F as False,
  find,
  gt,
  head,
  identity,
  ifElse,
  indexOf,
  last,
  length,
  lt,
  map,
  nth,
  pathOr,
  pickBy,
  pipe,
  prop,
  propEq,
  propOr,
  split,
  T,
  toLower,
} from 'ramda'
import { isNilOrEmpty, isNotNil, isTrue, notEqual, stubUndefined } from 'ramda-adjunct'
import { type Row } from 'react-table'
import { ColumnType } from 'src/lib/types'
import { type MloSetupDto } from 'src/routes/Investor/EditOrder/mlo-setup.types'
import { isValidJSON } from 'src/utils'

import { FeatureFlagsDto } from '../queries/useGetFeatureFlags'

// example: 18-Oct-2022 15:43 UTC
export const getFormattedDateEnteredBy = (val: string): string => {
  return `${DateTime.fromJSDate(new Date(val)).toUTC().toFormat('dd-LLL-y, HH:mm')}`
}

// example: 30 Aug 2022
export const getFormattedDate = (value: string): string =>
  DateTime.fromJSDate(new Date(value)).toUTC().toFormat('d LLL y')

export const getValidCurrency = (mloSetup: MloSetupDto): O.Option<Currency> =>
  F.pipe(
    O.fromNullable(mloSetup.currency),
    O.flatMapNullable(
      F.flow(
        A.findFirst((currency: DealSetupCurrency) => currency.acceptOrderSubmission),
        O.flatMap(F.flow(prop('currencyName'), O.fromNullable))
      )
    ),
    O.flatten
  )

export const getCurrencyValue = (mloSetup: MloSetupDto): string =>
  F.pipe(
    mloSetup,
    getValidCurrency,
    O.match({ onNone: F.constant('' as Currency), onSome: F.identity })
  )

export const removeUndefined = <T>(object: T): T => JSON.parse(JSON.stringify(object))

export const sortColumn = curry(
  (columnType: ColumnType, columnName: string, rowA: Row, rowB: Row) =>
    F.pipe(
      [rowA, rowB],
      map((row: Row) =>
        cond([
          [
            equals(ColumnType.DATE_ISO),
            always(
              ifElse(
                isNaN,
                always(0),
                identity
              )(DateTime.fromISO(row.values?.[columnName]).toMillis())
            ),
          ],
          [equals(ColumnType.TEXT), always(row.values?.[columnName]?.toLowerCase())],
          [T, always(row.values?.[columnName])],
        ])(columnType)
      ),
      ifElse(
        converge(curry(equals), [head, last]),
        always(identity),
        ifElse(converge(curry(gt), [head, last]), always(1), always(-1))
      )
    )
)

export const isAppEnabled = (enabledApplications: string[] | undefined): boolean =>
  enabledApplications?.map((appName) => appName.toLowerCase()).includes('ioi') ?? false

export const skipNullish = pickBy((val, key) => isNotNil(val))

export const isFeatureFlagEnabled = (
  featureFlagName: string,
  featureFlags?: FeatureFlagsDto[]
): boolean =>
  pipe(
    defaultTo([]),
    find(propEq('name', featureFlagName)),
    prop('enabled'),
    defaultTo(false),
    isTrue
  )(featureFlags)

export const textTransform = (value: string): any =>
  cond([
    [pipe(toLower, equals('true')), T],
    [pipe(toLower, equals('false')), False],
    [anyPass([isNilOrEmpty, equals('undefined')]), stubUndefined],
    [T, JSON.parse],
  ])(value)

export const formatDemandsValueForOrderIndicationScreen = curry((demands: string[]): string[] =>
  ifElse(
    pipe(head, notEqual('-')),
    map((demand: string) => Number(demand).toLocaleString()),
    always(demands)
  )(demands)
)

export const getRefetchInterval = (status: string, milliSeconds: number): number | false =>
  status === 'error' ? false : milliSeconds

export const retryIfErrorIsNotConnectionOrDistributionGroup = (error: {
  request: { response: string }
}): boolean => {
  return pipe(
    pathOr('', ['request', 'response']),
    cond([
      [
        isValidJSON,
        pipe(
          JSON.parse,
          propOr(400, 'statusCode'),
          allPass([notEqual(404), notEqual(406), notEqual(403), notEqual(400)])
        ),
      ],
      [T, always(true)],
    ])
  )(error)
}

export const hasLessThanTwoDigitsAfterDecimal = (num: number): boolean => {
  const numStr = num.toString()

  // Use Ramda's functions to check if there is no decimal point or if there are less than 2 characters after it
  return either(
    pipe(indexOf('.'), equals(-1)), // Check if there is no decimal point
    pipe(
      split('.'),
      nth(1),
      defaultTo(''), // Handle the case where there is no decimal part
      length,
      lt(__, 2) // Check if the length is less than 2
    )
  )(numStr)
}

export const formatOrderIndicationsLimitNumber = (
  limits: string[],
  limitFactor: string
): string[] => {
  return limits.map((limit) => {
    const limitNumber = parseFloat(limit)

    if (limit === '-') {
      return limit
    }

    if (isNaN(parseFloat(limit))) {
      return 'strike'
    }

    if (limitFactor === 'LIMIT_FACTOR_DISCOUNT') {
      return limit
    }

    if (limit === undefined || limit === '') {
      return 'strike'
    }

    if (hasLessThanTwoDigitsAfterDecimal(limitNumber)) {
      return limitNumber.toFixed(2)
    }

    return limit
  })
}

export const isDemandCancelled = (status: OrderStatus): boolean => {
  return [OrderStatus.CANCELLED_ACKNOWLEDGED, OrderStatus.CANCELLED_OUTSTANDING].includes(status)
}
