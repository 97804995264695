import React, { ReactNode } from 'react'

import { AppContainerFullWidth } from './AppContainerFullWidth'
import { AppContainerWithMargins } from './AppContainerWithMargins'

interface Props {
  children: ReactNode
  fullWidth?: boolean
}

export function AppContainer({ children, fullWidth = false }: Props): JSX.Element {
  return fullWidth ? (
    <AppContainerFullWidth>
      <div>{children}</div>
    </AppContainerFullWidth>
  ) : (
    <AppContainerWithMargins>
      <div>{children}</div>
    </AppContainerWithMargins>
  )
}
