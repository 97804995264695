export enum OfferingType {
  IPO = 'IPO',
  ABO = 'ABO',
  EQL = 'EQL',
  RI = 'RI',
  SPAC = 'SPAC',
  OTH = 'OTH',
}

export enum DealState {
  LIVE = 'LIVE',
  ANNOUNCED = 'ANNOUNCED',
  PRICED = 'PRICED',
  WITHDRAWN = 'WITHDRAWN',
}

export enum Region {
  EMEA = 'EMEA',
  APAC = 'APAC',
  AMRS = 'AMRS',
}

export enum DistributionGroup {
  QIBS = 'QIBS',
  REG_S = 'REG_S',
}

export enum DealSetupError {
  DISTRIBUTION_GROUP_IS_NOT_ALLOWED = 'DISTRIBUTION_GROUP_IS_NOT_ALLOWED',
  NO_CONNECTED_BANKS_FOUND = 'NO_CONNECTED_BANKS_FOUND',
}
