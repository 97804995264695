import SecondaryHeader from 'components/Header/SecondaryHeader'
import { NoMobileVersionMessage } from 'components/NoMobileVersionMessage/NoMobileVersionMessage'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export function AppContainerFullWidth({ children }: Props): JSX.Element {
  return (
    <>
      <div className="ioi-bg-grey_200 ioi-hidden md:ioi-flex ioi-flex-col">
        <div>
          <div className="ioi-flex ioi-flex-grow ioi-ml-2">
            <SecondaryHeader fullWidth />
          </div>
        </div>
        <div className="ioi-flex ioi-flex-grow">
          <div className="ioi-flex ioi-flex-grow ioi-flex-col ioi-mx-auto">
            <main>
              <div>{children ?? <span />}</div>
            </main>
          </div>
        </div>
      </div>
      <div className="ioi-h-screen ioi-flex md:ioi-hidden ioi-flex-col">
        <div className="ioi-h-full ioi-max-w-lg">
          <NoMobileVersionMessage />
        </div>
      </div>
    </>
  )
}
