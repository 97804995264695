import { makeAutoObservable } from 'mobx'

import { RootStore } from '../rootStore'
import { AlertData } from './types'

export class AlertStore {
  private readonly rootStore: RootStore
  private _shouldBeClosed: boolean = true
  private _alertData: AlertData = {
    message: null,
    type: null,
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get shouldBeClosed(): boolean {
    return this._shouldBeClosed
  }

  get alertData(): AlertData {
    return this._alertData
  }

  showAlert(payload: AlertData): void {
    this._shouldBeClosed = false
    this._alertData = payload
  }

  closeAlert(): void {
    this._shouldBeClosed = true
    this._alertData = {
      message: null,
      type: null,
    }
  }

  closeAfter(ms: number): void {
    setTimeout(() => this.closeAlert(), ms)
  }

  showTemporaryAlert({ alert, ms }: { alert: AlertData; ms?: number }): void {
    this.showAlert(alert)
    this.closeAfter(ms ?? 0)
  }

  clear(): void {
    this._shouldBeClosed = true
    this._alertData = {
      message: null,
      type: null,
    }
  }
}
