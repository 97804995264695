import { AccountType, Currency, DeliveryTypeOptions, DemandFactorOptions } from '@shared/enums'

import { limitFactorOptionsType } from '../components/Orders/OrderForm/helpers'

export enum ReactQueryKey {
  CURRENT_MLO_SETUP = 'Current MLO setup',
  USE_ORDER_INDICATIONS = 'Use Order Indications',
  FEATURE_FLAGS = 'Feature Flags',
  ORGANIZATION_BY_ORDER_PREFERENCE = 'Organization by Order Preference',
  BANKER_INDICATION_OVERVIEWS = 'Banker indication overviews',
  BANKER_DEAL_ORDERS = 'Banker Orders for deal',
  API_DOCS = 'Api docs',
  BANKER_DEAL_SETUP_DETAILS = 'Banker Deal Setup details',
  SLIDER_ORDER_HISTORY = 'Slider Order History',
  SELL_SIDE_SALES_DEAL_SETUPS = 'Sell-side sales Deal Setups',
  USER_PROFILE = 'User profile',
  SELL_SIDE_SALES_DEAL_ORDERS = 'Sell-side Orders for deal',
  DESKS = 'Desks for Desk Management',
  DATO_CMS_USER_GUIDE = 'User Guide Query From Dato CMS',
  BANKER_DASHBOARD = 'Banker Dashboard',
}

export interface FormDemand {
  demandValue: number
  demandFactor: DemandFactorOptions | Currency
  limitValue?: number
  limitFactor?: limitFactorOptionsType | Currency
  deliveryType: DeliveryTypeOptions | Currency
}
export interface Demand {
  demandValue: number
  limitValue?: number
  isStrike: boolean
}

export interface UserInfo {
  cognitoId: string
  givenName: string
  familyName: string
  email: string
  organizationName: string
  organizationId: string
  accountType?: AccountType
}

export enum ColumnType {
  DATE_ISO = 'dateISO',
  TEXT = 'text',
}

export enum AlertType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface AlertMessage {
  title?: string
  description: string
}

export interface AlertData {
  type: AlertType | null
  message?: AlertMessage | null
  customClasses?: string
}
export interface ApiError {
  request: { response: string }
}
