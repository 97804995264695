export enum MLOAction {
  CANCEL = 'CANCEL',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
}

export enum OrderStatus {
  START = 'START',
  OUTSTANDING = 'OUTSTANDING',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  DECLINED = 'DECLINED',
  CANCELLED_OUTSTANDING = 'CANCELLED_OUTSTANDING',
  CANCELLED_ACKNOWLEDGED = 'CANCELLED_ACKNOWLEDGED',
  UPDATING = 'UPDATING',
}

export enum OrderAction {
  SUBMIT_NEW = 'SUBMIT_NEW',
  ACKNOWLEDGE = 'ACKNOWLEDGE',
  DECLINE = 'DECLINE',
  CANCEL = 'CANCEL',
  UPDATE = 'UPDATE',
}

export enum MLOError {
  VERSION_CONDITION_ERROR = 'VERSION_CONDITION_ERROR',
  ALREADY_CREATED_ERROR = 'ALREADY_CREATED_ERROR',
  DEAL_SETUP_IS_PAUSED = 'DEAL_SETUP_IS_PAUSED',
  DEAL_SETUP_IS_DISABLED = 'DEAL_SETUP_IS_DISABLED',
  USER_IS_NOT_ON_PUBLIC_MLO_DESK = 'USER_IS_NOT_ON_PUBLIC_MLO_DESK',
  USER_IS_NOT_ON_PRIVATE_MLO_DESK = 'USER_IS_NOT_ON_PRIVATE_MLO_DESK',
  DEAL_IS_NOT_AVAILABLE_FOR_ORDER_INDICATION_ENTRY = 'DEAL_IS_NOT_AVAILABLE_FOR_ORDER_INDICATION_ENTRY',
  USER_DISCLAIMER_NOT_ACCEPTED = 'USER_DISCLAIMER_NOT_ACCEPTED',
}

export enum DemandFactorOptions {
  DEAL_SIZE = 'DEAL_SIZE',
  SHARES = 'SHARES',
  GDR = 'GDR',
  UNITS = 'UNITS',
}

export enum DeliveryTypeOptions {
  GDR_REG_S = 'GDR_REG_S',
  GDR_144A = 'GDR_144A',
  SHARES_ANOTHER_CURRENCY = 'SHARES_ANOTHER_CURRENCY',
  UNITS = 'UNITS',
  GDR = 'GDR',
}
