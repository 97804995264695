import { OrderFormType } from 'components/Orders/OrderForm/types'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'src/store/rootStore'

export class OrderStore {
  private readonly rootStore: RootStore
  private _isValidNewForm: boolean = false
  private _isValidEditForm: boolean = true
  private _isOrderDetailsEdited: boolean = false
  private _isLimitChanged: boolean = false
  private _isFormFilled: boolean = false
  private _randomNumber: number = Math.random()
  private _formValue: OrderFormType = {
    pmName: '',
    additionalInfo: '',
    banks: [],
    demands: [],
    CC: {
      isCCSelected: false,
      CCEmails: '',
    },
    version: 0,
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get isFormFilled(): boolean {
    return this._isFormFilled
  }

  set isFormFilled(value: boolean) {
    this._isFormFilled = value
  }

  set randomNumber(value) {
    this._randomNumber = value
  }

  get randomNumber(): number {
    return this._randomNumber
  }

  set formValue(value: OrderFormType) {
    this._formValue = value
  }

  get formValue(): OrderFormType {
    return this._formValue
  }

  set isLimitChanged(value: boolean) {
    this._isLimitChanged = value
  }

  get isLimitChanged(): boolean {
    return this._isLimitChanged
  }

  set isValidNewForm(value: boolean) {
    this._isValidNewForm = value
  }

  get isValidNewForm(): boolean {
    return this._isValidNewForm
  }

  set isValidEditForm(value: boolean) {
    this._isValidEditForm = value
  }

  get isValidEditForm(): boolean {
    return this._isValidEditForm
  }

  set isOrderDetailsEdited(value: boolean) {
    this._isOrderDetailsEdited = value
  }

  get isOrderDetailsEdited(): boolean {
    return this._isOrderDetailsEdited
  }

  clear(): void {
    this._isValidNewForm = false
    this._isValidEditForm = true
    this._isOrderDetailsEdited = false
    this._isLimitChanged = false
  }
}
