import { Auth, CognitoUser } from '@aws-amplify/auth'
import {
  getUser as mfeGetUser,
  signOut as mfeSignOut,
  updateUserAttributes as mfeUpdateUserAttributes,
} from '@pp/utils'

import { onError } from '../lib/errorLib'

interface SentrifiedAuthReturn {
  signOut: () => Promise<unknown>
  currentAuthenticatedUser: () => Promise<unknown>
  updateUserAttributes: (currentUser: CognitoUser, attribute: object) => Promise<unknown>
}

export function SentrifiedAuth(isMfe: boolean): SentrifiedAuthReturn {
  const { awsSignOut, awsCurrentAuthenticatedUser, awsUpdateUserAttributes } = isMfe
    ? {
        awsSignOut: async () => await mfeSignOut(),
        awsCurrentAuthenticatedUser: async () => await mfeGetUser(),
        awsUpdateUserAttributes: async (
          user: CognitoUser | any,
          attributes: object,
          clientMetadata?: Record<string, string>
        ) => await mfeUpdateUserAttributes(user, attributes, clientMetadata),
      }
    : {
        awsSignOut: async () => await Auth.signOut(),
        awsCurrentAuthenticatedUser: async () => await Auth.currentAuthenticatedUser(),
        awsUpdateUserAttributes: async (
          user: CognitoUser | any,
          attributes: object,
          clientMetadata?: Record<string, string>
        ) => await Auth.updateUserAttributes(user, attributes, clientMetadata),
      }

  const signOut = async (): Promise<unknown> =>
    await awsSignOut()
      .then(() => {
        window.location.replace(window.location.origin)
      })
      .catch((error) => onError({ error, AWSAmplifyMethod: 'Auth.signOut()' }))

  const currentAuthenticatedUser = async (): Promise<unknown> =>
    await awsCurrentAuthenticatedUser().catch((error) =>
      onError({ error, AWSAmplifyMethod: 'Auth.currentAuthenticatedUser()' })
    )

  const updateUserAttributes = async (user: CognitoUser, attributes: object): Promise<unknown> => {
    return await awsUpdateUserAttributes(user, attributes).catch((error) =>
      onError({ error, AWSAmplifyMethod: 'Auth.updateUserAttributes()' })
    )
  }

  return {
    signOut,
    currentAuthenticatedUser,
    updateUserAttributes,
  }
}
