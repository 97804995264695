import React from 'react'
import { IOI_SUPPORT_EMAIL } from 'src/const'

export function ErrorPage(): JSX.Element {
  return (
    <div className="ioi-grid ioi-place-items-center ioi-h-[calc(100vh-12rem)]">
      <h3>
        An error has occurred please try again later or contact{' '}
        <a
          href={`mailto:${IOI_SUPPORT_EMAIL}`}
          className="ioi-underline ioi-text-blue-600 ioi-font-bold"
        >
          {IOI_SUPPORT_EMAIL}
        </a>
      </h3>
    </div>
  )
}
