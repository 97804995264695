import SecondaryHeader from 'components/Header/SecondaryHeader'
import { NoMobileVersionMessage } from 'components/NoMobileVersionMessage/NoMobileVersionMessage'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export function AppContainerWithMargins({ children }: Props): JSX.Element {
  return (
    <>
      <div className="ioi-bg-grey_200 ioi-hidden md:ioi-flex ioi-flex-col ioi-px-10 max-xl:ioi-px-4">
        <div className="ioi-flex ioi-flex-grow ioi-flex-col ">
          <SecondaryHeader />
          <div id="ioi-alerts" />
          <main>
            <div>{children ?? <span />}</div>
          </main>
        </div>
      </div>
      <div className="ioi-h-screen ioi-flex md:ioi-hidden ioi-flex-col">
        <div className="ioi-h-full ioi-max-w-lg">
          <NoMobileVersionMessage />
        </div>
      </div>
    </>
  )
}
