/* eslint-disable no-console */
import React, { ReactNode } from 'react'
import { QueryClient, QueryClientProvider, setLogger } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './contexts'

setLogger({
  log: console.log,
  warn: console.warn,
  error: console.warn,
})
const queryClient = new QueryClient()

export function Providers({
  envName,
  children,
  isMfe = false,
}: {
  envName: string
  children: ReactNode
  isMfe?: boolean
}): JSX.Element {
  return (
    <div className="ioi">
      <BrowserRouter basename={isMfe ? '/ioi' : ''}>
        <AuthProvider env={envName} isMfe={isMfe}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  )
}
