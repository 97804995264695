import React from 'react'

const NotFound = (): JSX.Element => {
  return (
    <div
      className="ioi-grid ioi-justify-items-center ioi-mt-20 ioi-max-h-[calc(100vh-12rem)]"
      data-qa="ioi.not-found.container"
    >
      <h3>Sorry, page not found!</h3>
    </div>
  )
}
export default React.memo(NotFound)
